






















































import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'
import { getModule } from 'vuex-module-decorators'

const UserModule = getModule(UserInfo, store)

@Component
export default class AccountInfo extends Vue {
  private email: any = ''

  created() {
    this.getUserInfo()
  }

  getUserInfo() {
    UserService.getUserInfo()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_INFO(response.data)
          this.email = response.data.email
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  goToChangeEmailRoute() {
    return ({ name: 'change-email' })
  }

  goToChangePasswordRoute() {
    return ({ name: 'change-password' })
  }
}
